@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0, 0%, 100%;
    --btn-background: 200 10% 91%;
    --btn-background-hover: 200 10% 89%;
    --foreground: 200 50% 3%;
    line-height: 1.6;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
  }

  @media (prefers-color-scheme: dark) {
    /* :root {
      --background: 0, 0%, 6%;
      --btn-background: 200 10% 9%;
      --btn-background-hover: 200 10% 12%;
      --foreground: 200 20% 96%;
      color: 55, 10%, 79%;
    } */
  }
}

@layer base {
  * {
    @apply border-foreground/20;
  }
}

.animate-in {
  animation: animateIn 0.3s ease 0.15s both;
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* CSTM */

a, .link-color {
  color: #1976D2;
}

.btn-active {
  background-color: #1976D2;
  color: white;
}

.btn-active:hover {
  background-color: #205EA6;
  color: white;
}

.color1 {
  background-color: #e8464e;
}
.color2 {
  background-color: #2b3956;
}
.color3 {
  background-color: #4b7196;
}
.color4 {
  background-color: #afd0db;
}

.screen-height {
  min-height: 65vh;
}

.tooltip:hover:after{
  content: attr(data-tooltip);
  position: absolute;
  padding: 0.4rem 0.6rem;
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 0.8rem;
  background-color: whitesmoke;
  color: black;
  z-index: 1000;
  max-width: 300px;
  font-weight: 400;
}

.top-gradient {
  position: sticky;
  top: 0px;
  box-shadow: 0 0 1px 0 rgba(128, 128, 128, 0.5);
  height: 4rem;
  border-top: 4px solid;
  border-image-source: linear-gradient(90deg, red, #2B3956, #4B7196, #CFE2F3 75%);
  border-image-slice: 1;
  border-bottom: 0;
}

/* details and summary */

details {
  border: 0;
  padding: 10px;
  margin: 10px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

summary {
  display: flex; 
  align-items: center; 
  cursor: pointer;
  margin: -10px -10px -10px -10px;
  padding: 10px;
  cursor: pointer;
  background-color: hsla(var(--btn-background));
  border-radius: 5px;
  list-style: none; /* Removes the default arrow icon in Chrome */
}

/* summary h3 {
  margin: 0; 
  font-size: 1rem;
  font-weight: bold;
} */

summary:hover {
  background-color: hsla(var(--btn-background-hover));
}

summary::-webkit-details-marker { /* Hide default arrow in Chrome */
  display: none;
}

details[open] summary {
  border-bottom: none;
  background-color: hsla(var(--btn-background-hover));
  /* border-bottom: 1px solid #ddd; */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

details p {
  margin: 30px 10px 10px;
  line-height: 1.5;
}

details[open] {
  border: 1px solid hsla(var(--btn-background));
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Optional: Add an icon for the summary element */
summary:before {
  content: '▶';
  font-size: 12px;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
  transition: transform 0.3s ease;
}

details[open] summary:before {
  transform: rotate(90deg); /* Rotates the icon when details are open */
}
