.bar {
  margin: 0 auto;
  width: 100%;
  max-width: 575px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.bar:hover {
  box-shadow: 1px 1px 8px 1px #dcdcdc;
}

.bar:focus-within {
  box-shadow: 1px 1px 8px 1px #dcdcdc;
  outline: none;
}

.suggestions {
  list-style-type: none;
  overflow: hidden;
  width: 100%;
  max-width: 575px;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
}

.suggestion-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
}

.search-icon {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  color: #888;
}

.search-icon:focus {
  outline: none;
}

@media (max-width: 600px) {
  .bar {
    width: 90%;
  }
  .suggestion-item {
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
  }
}